@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


@layer base {
    html {
      font-family: "", system-ui, sans-serif;
    }
}